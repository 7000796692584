import React, { useState, useCallback, useContext } from 'react';
import Toast from './Toast';
import './Toast.css';

const ToastContext = React.createContext({});

const ToastProvider = ({ children }) => {
  let id = 1;

  const [toasts, setToasts] = useState([]);

  const addToast = useCallback(
    (content, state = 'success') => {
      setToasts((toasts) => [...toasts, { id: id++, content, state }]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setToasts]
  );

  const removeToast = useCallback(
    (id) => {
      setToasts((toasts) => toasts.filter((t) => t.id !== id));
    },
    [setToasts]
  );

  return (
    <ToastContext.Provider value={{ addToast, removeToast, toasts }}>
      <>
        <div className="toast-container">
          {toasts.map((toast) => (
            <Toast toast={toast} key={toast.id} />
          ))}
        </div>
        {children}
      </>
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);

export default ToastProvider;
