import React, { useEffect, useState } from 'react';
import { postAvvik } from '../../utils';
import { useToast } from '../Toast/ToastProvider';
import { ReactComponent as Camera } from '../../assets/camera.svg';
import { ReactComponent as CrossCircle } from '../../assets/cross-circle.svg';
import Spinner from './Spinner';
import './AvvikForm.css';
import LocationAvailableChecker from '../LocationAvailableChecker/LocationAvailableChecker';
import Resizer from 'react-image-file-resizer';

const AvvikForm = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayGeoError, setdisplayGeoError] = useState(false);
  const { addToast } = useToast();

  const resizeFile = async (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        'PNG',
        70,
        0,
        async (uri) => {
          resolve({ img: uri, name: file.name });
        },
        'blob'
      );
    });

  const onAddImages = async (files) => {
    const images = Array.from(files);
    const compressedImages = [];
    for (let i = 0; i < images.length; i++) {
      compressedImages.push(await resizeFile(images[i]));
    }
    setImages(compressedImages);
    // getLocation(setLocation);
  };

  const onRemoveImage = (index) => {
    setImages([...images.filter((_, i) => i !== index)]);
  };

  const testGeoLocationAccess = () => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        setdisplayGeoError(false);
      },
      () => {
        setdisplayGeoError(true);
      }
    );
  };

  const getGeoLocation = async () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };

  const getLocation = async () => {
    try {
      const location = await getGeoLocation(
        (geo) => geo,
        (error) => {
          throw Error(error);
        }
      );
      return location;
    } catch (e) {
      if (e.code === 1) {
        addToast(
          'Du må tillate bruk av posisjon for å kunne melde inn avvik.',
          'error'
        );
      } else if (e.code === 1) {
        addToast('Lokasjon er utilgjengelig.', 'error');
      } else {
        addToast('Kunne ikke bruke enhetes lokasjon.', 'error');
      }
      return undefined;
    }
  };

  const onSubmitAvvik = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const location = await getLocation();
      if (!location) return;

      let form = new FormData();

      form.set('latitude', location.coords.latitude);
      form.set('longitude', location.coords.longitude);

      images.forEach((bilde) => {
        form.append('bilder', bilde.img, bilde.name);
      });

      await postAvvik(form);
      addToast('Avvik ble lagret');
      setImages([]);
    } catch (e) {
      addToast(
        `Noe gikk galt. Prøv å legge inn på nytt.
          ${e}`,
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    testGeoLocationAccess();
  }, []);

  return (
    <>
      {displayGeoError && (
        <LocationAvailableChecker
          testGeoLocationAccess={testGeoLocationAccess}
        />
      )}
      <form onSubmit={onSubmitAvvik}>
        {images.length === 0 ? (
          <label className="legg-til-bilde animate-bounce">
            <Camera />
            Ta et bilde
            <input
              type="file"
              className="input"
              accept="image/*"
              capture="camera"
              onChange={(e) => onAddImages(e.target.files)}
            />
          </label>
        ) : (
          <div className="add-images-preview-item-container">
            {images.map((b, index) => {
              return (
                <div className="add-images-preview-item" key={b.name}>
                  <button
                    type="button"
                    className="add-images-preview-item-remove"
                    onClick={() => onRemoveImage(index)}
                  >
                    <CrossCircle />
                  </button>
                  <img
                    className="add-images-preview-item-img"
                    src={URL.createObjectURL(b.img)}
                    alt={b.name}
                  />
                </div>
              );
            })}
          </div>
        )}
        {images.length > 0 && (
          <button type="submit" className="btn btn-green" disabled={loading}>
            Rapporter avvik her
            {loading && <Spinner />}
          </button>
        )}
      </form>
    </>
  );
};

export default AvvikForm;
