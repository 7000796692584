import axios from "axios";

const getEnvironmentVariable = (key, defaultValue) => {
  const env = window[key] || process.env[key];
  if (env != null) {
    console.log(`${key} : ${env}`);
    return env;
  }
  if (defaultValue) return defaultValue;
  throw new Error(`Missing environment configuration with key '${key}'`);
};

const enkUrl = getEnvironmentVariable("REACT_APP_URL_SERVICE");

export const postAvvik = async (form) => {
  const res = await axios({
    url: `${enkUrl}/api/avvik/broyting`,
    method: "POST",
    data: form,
    headers: {
      "Content-Type": `multipart/form-data; boundary=${form._boundary}`,
    },
  });
  return res;
};

export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

export const isInStandaloneMode = () =>
  "standalone" in window.navigator && window.navigator.standalone;
