import React, { useEffect, useState } from 'react';
import { isIos, isInStandaloneMode } from '../../utils';
import shareBtnImg from '../../assets/ShareBTN.png';
import addToHomeBtnImg from '../../assets/AddToHomeBTN.png';
import leggTilBtnImg from '../../assets/LeggTilBTN.png';
import { ReactComponent as CrossCircle } from '../../assets/cross-circle.svg';
import { useToast } from '../Toast/ToastProvider';

import './AddToHomescreen.css';

const AddToHomescreen = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const { toasts } = useToast();

  useEffect(() => {
    setIsVisible(isIos() && !isInStandaloneMode() && toasts.length === 0);
  }, [toasts]);

  return (
    isVisible && (
      <div
        className={`add-to-homescreen-container ${
          expanded
            ? 'add-to-homescreen-expanded'
            : 'add-to-homescreen-not-expanded'
        }`}
      >
        <div className="add-to-homescreen-content">
          <div className="add-to-homescreen-header">
            <button
              className="add-to-homescreen-expand-btn"
              onClick={() => setExpanded(!expanded)}
            >
              <h2 className="add-to-homescreen-heading">
                Legg til snarvei på hjem-skjermen
              </h2>
            </button>
            <button
              className="add-to-homescreen-close-btn"
              onClick={() => setIsVisible(false)}
            >
              <CrossCircle />
            </button>
          </div>
          <p>
            Klikk på "del" knappen
            <img alt="Del-knapp" src={shareBtnImg} />
          </p>
          <p>
            Velg "Legg til på Hjem-skjermen"
            <img alt="Dele-meny" src={addToHomeBtnImg} />
          </p>
          <p>
            Klikk på "Legg til"
            <img alt="Legg til knapp" src={leggTilBtnImg} />
          </p>
        </div>
      </div>
    )
  );
};

export default AddToHomescreen;
