import React from 'react';
import './LocationAvailableChecker.css';

const LocationAvailableChecker = ({ testGeoLocationAccess }) => {
  return (
    <div className="lac-container">
      <p>Denne nettsiden trenger tilgang til lokasjon for å fungere</p>
      <p>
        Les mer her:
        <br />
        <a
          target="_"
          href="https://support.google.com/chrome/answer/142065?hl=no"
        >
          Chrome
        </a>
        <br />
        <a target="_" href="https://support.apple.com/no-no/HT207092">
          Safari
        </a>
      </p>
      <button className="lac-button" onClick={testGeoLocationAccess}>
        Test tilgang igjen!
      </button>
    </div>
  );
};

export default LocationAvailableChecker;
