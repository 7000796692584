import React, { useEffect } from "react";
import { useToast } from "./ToastProvider";

const Toast = ({ toast }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(toast.id);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [toast, removeToast]);

  return (
    <div
      key={toast.id}
      className={`toast-item ${
        toast.state === "success" ? "toast-item-success" : "toast-item-error"
      }`}
      id={toast.id}
    >
      {toast.content}
    </div>
  );
};

export default Toast;
