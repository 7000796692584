import React from 'react';
import AvvikForm from './Features/AvvikForm/AvvikForm';
import AddToHomescreen from './Features/AddToHomescreen/AddToHomescreen';
import ToastProvider from './Features/Toast/ToastProvider';

import './App.css';

function App() {
  return (
    <ToastProvider>
      <div className="app">
        <div className="description">
          <h1>Rapporter avvik</h1>
          <p>
            Dersom du støter på en hindring, ta et bilde og send det inn til
            Bymiljøetaten her for å registrere et avvik.
          </p>
        </div>
        <AvvikForm />
        <AddToHomescreen />
      </div>
    </ToastProvider>
  );
}

export default App;
